<template>
  <div class="guide" :class="this.class">
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div class="message">
      <slot></slot>
    </div>
    <div class="character">
      <img :src="require(`../assets/${image}_yk.png`)" />
    </div>
    <div class="guide-image">
      <img :src="require(`../assets/${image}.png`)" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Guide",
  props: ["title", "image", "class"],
};
</script>

<style lang="scss" scoped>
.guide {
  overflow: hidden;
  line-height: 0;
  .title {
    font-family: "GothamSSm";
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
    letter-spacing: -0.266px;
  }
  .character {
    width: calc(427px / 3);
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .message {
    font-family: "Noto Sans KR";
    font-size: 13px;
    line-height: 1;
    margin-top: 9px;
  }
  .guide-image {
    width: 245px;
    margin: 0 auto;
    margin-top: 16px;
    img {
      width: 100%;
    }
  }
  &.style {
    .character {
      margin-top: 23px;
    }
    .guide-image {
      margin-top: 19px;
    }
  }
  &.vr {
    .character {
      width: calc(422px / 3);
      margin-top: 17px;
    }
    .guide-image {
      width: 244px;
      margin-top: 10px;
    }
  }
}
</style>
